.home-container--button{
    border-radius: var(--botder-radius);
    background-color: var(--info);
    color:var(--background-light);
    min-width: 216px;
    max-width: 216px;
    padding: 15px;
    border: inherit;
    cursor: pointer;
    text-transform: uppercase;
}

.home-container--button-block{
    display: flex;
    flex-direction: row;
}

.home-container--button-block--left{
    justify-content: flex-end;
}

.home-container--column{
    width: 100%;
    min-width: 20rem;
}

.home-container--column--mobile{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
}

.home-container--column--desktop{
    display: none;
}


.home-container--widget-block{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.container--widget
{
    background: white;
    border-radius: 4px;
    padding: 0.5rem;
    box-sizing: border-box;
}

.home-container--title{
    font-size: var(--font-size-lg-mobile);
    font-weight: 600;
}

.home--button{
    width: 100%;
    border-radius: var(--botder-radius);
    background-color: var(--info);
    color:var(--background-light);
    padding: 15px;
    border: inherit;
    cursor: pointer;
    text-transform: uppercase;
}