
  /* For large screens */
@media screen and (min-width: 768px) {
    /* FOOTER MOBILE */
    .footer--mobile{
        display: none;
    }
    
    /* HOME */
    .home-container--column--desktop{
        display: flex;
        flex-direction: column;
    }

    .home-container--widget-block--mobile{
        display: none;
    }

    .home-container--title{
        font-size: var(--font-size-lg-desktop);
        font-weight: 600;
    }

    /* FOOTER */
    .footer--price-text{
        font-size: var(--font-size-md-desktop);
    }


    /* DISCOUNT BOX */
    .discount-box--input{
        border: black solid 0.1px;
    }


   .card--info-value-block{
        height: auto;
        gap: 5%;
    }

    .card--price-block{
        height: auto;
        gap: 0;
    }

    /* Mit payment screen */
    .payment-confirmation-container{
        width: 50%;
    }
}

