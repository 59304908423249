.footer{
    width: 100%;
    height:5rem; 
    position: fixed;
    text-align: center;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background:var(--background-light);
    box-sizing: border-box;
    padding: 0 var(--padding-block);
}

.footer--button{
    border-radius: var(--botder-radius);
    background-color: var(--info);
    color:var(--background-light);
    min-width: 216px;
    max-width: 216px;
    padding: 15px;
    border: inherit;
    cursor: pointer;
    text-transform: uppercase;
}

.footer--price-text-column{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.footer--price-text-block{
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.footer--price-text{
    font-size: var(--font-size-md-mobile);
}

.footer--price-text--green{
    color: var(--success);
    font-weight: 700;
}

.footer--price-text--red{
    color:var(--danger);
    font-weight: 700;
}