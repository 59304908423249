

.card--info-block{
    width: 100%;
    height: 100%;
    flex: 5;
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.card--image-block{
    height: 100%;
    flex: 6;
}

.card--image{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card--info-value-block{
    height: 100%;
    flex : 6;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.3rem;
}

.card--product-title{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: var(--font-size-md-mobile);
    font-weight: 700;
}

.card--product-value{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
}

.card--product-title--blue{
    color: var(--info);
}

.card--price-block{
    width: 100%;
    height: 100%;
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.5rem;
}

.card--detail-block{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.card--price-header-block{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.card--price-value-block{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.card--total-block{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.card--total-title-block,.card--total-value-block{
    display: flex;
    flex-direction: column;
}

.card--total-title {
    font-size: 16px;
    font-weight: 700;
} 

.card--total-title--red{
    color: var(--danger);
}

.card--total-title--green{
    color: var(--success);
}
